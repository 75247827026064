(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/confetti-cannon/assets/javascripts/confetti-cannon.js') >= 0) return;  svs.modules.push('/components/tur/confetti-cannon/assets/javascripts/confetti-cannon.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
((svs, _) => {
  svs = svs || {};
  svs.tur = svs.tur || {};
  svs.tur.confettiCannon = options => {
    let CONSTANTS = {
      baseGravity: Math.min(window.innerWidth, window.innerHeight) * 0.0006,
      baseVelocityX: 0.031,
      baseVelocityY: 0.022,
      baseAirFrictionX: 0.0005,
      baseAirFrictionY: 0.00054,
      numberOfTimes: 2,
      xSpread: 4.5,
      ySpread: 1.8,
      canonHeight: 0.3,
      particlesPerBang: 100,
      interValPerBang: 300,
      canvasClasses: 'confetti-canvas',
      sizeSpan: [13, 15],
      spinSpan: [5, 15],
      removeAfter: 15000,
      colors: {
        ch: [0, 360],
        cs: [100, 100],
        cl: [35, 60]
      }
    };
    CONSTANTS = _objectSpread(_objectSpread({}, CONSTANTS), options);
    const confettiSpriteIds = [];
    const confettiSprites = {};
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    document.body.appendChild(canvas);
    window.addEventListener('resize', () => {
      setCanvasSize();
    });
    function bang() {
      let i = 0;
      const intr = setInterval(() => {
        addConfettiParticles(CONSTANTS.particlesPerBang, {
          x: i % 2 ? 0 : window.innerWidth,
          y: window.innerHeight * CONSTANTS.canonHeight
        }, {
          x: i % 2 ? -window.innerWidth * CONSTANTS.baseVelocityX : window.innerWidth * CONSTANTS.baseVelocityX,
          y: -window.innerHeight * CONSTANTS.baseVelocityY
        });
        i++;
        i === Number(CONSTANTS.numberOfTimes) && clearInterval(intr);
      }, CONSTANTS.interValPerBang);
    }
    function setCanvasSize() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      canvas.style.width = "".concat(window.innerWidth, "px");
      canvas.style.height = "".concat(window.innerHeight, "px");
      canvas.className = CONSTANTS.canvasClasses;
    }
    function addConfettiParticles(amount, position, velocity) {
      let i = 0;
      while (i < amount) {
        const r = _.random(5, 10);
        const d = _.random(CONSTANTS.sizeSpan[0], CONSTANTS.sizeSpan[1]);
        const ch = _.random(CONSTANTS.colors.ch[0], CONSTANTS.colors.ch[1]);
        const cs = _.random(CONSTANTS.colors.cs[0], CONSTANTS.colors.cs[1]);
        const cl = _.random(CONSTANTS.colors.cl[0], CONSTANTS.colors.cl[1]);
        const color = "hsl(".concat(ch, ", ").concat(cs, "%, ").concat(cl, "%)");
        const tilt = _.random(10, -10);
        const tiltAngleIncremental = _.random(CONSTANTS.spinSpan[0], CONSTANTS.spinSpan[1]) / 100;
        const tiltAngle = 10;
        const angle = _.random(180);
        const velocityX = _.random(velocity.x / CONSTANTS.ySpread * 100, velocity.x * 100) / 100;
        const velocityY = _.random(velocity.y / CONSTANTS.xSpread * 100, velocity.y * 100) / 100;
        const gravity = CONSTANTS.baseGravity;
        const airFrictionX = window.innerWidth * CONSTANTS.baseAirFrictionX;
        const airFrictionY = Math.min(window.innerWidth, window.innerHeight) * CONSTANTS.baseAirFrictionY;
        const {
          x,
          y
        } = position;
        const id = _.uniqueId();
        const sprite = {
          angle,
          velocityY,
          velocityX,
          x,
          y,
          gravity,
          airFrictionX,
          airFrictionY,
          r,
          d,
          color,
          tilt,
          tiltAngleIncremental,
          tiltAngle
        };
        confettiSprites[id] = sprite;
        confettiSpriteIds.push(id);
        setTimeout(() => {
          confettiSpriteIds.splice(confettiSpriteIds.indexOf(id), 1);
        }, CONSTANTS.removeAfter);
        i++;
      }
    }
    function updateConfettiParticle(id) {
      const sprite = confettiSprites[id];
      const tiltAngle = 0.0005 * sprite.d;
      sprite.angle += 0.01;
      sprite.tiltAngle += tiltAngle;
      sprite.tiltAngle += sprite.tiltAngleIncremental;
      sprite.tilt = Math.sin(sprite.tiltAngle - sprite.r / 2) * sprite.r * 0.5;
      sprite.x += Math.cos(sprite.angle - sprite.velocityY);
      sprite.x -= sprite.velocityX;
      sprite.y += sprite.velocityY;
      sprite.velocityX += sprite.velocityX < 0 ? sprite.airFrictionX : -sprite.airFrictionX;
      sprite.velocityY += sprite.gravity;
      sprite.velocityY += sprite.velocityY < 0 ? sprite.airFrictionY : -(sprite.airFrictionY + sprite.tilt / 1000);
      sprite.y += Math.sin(sprite.angle + sprite.r / 2) * 2;
      sprite.r = Math.abs(sprite.r - 0.01);
    }
    function drawConfetti() {
      confettiSpriteIds.forEach(id => {
        const sprite = confettiSprites[id];
        ctx.beginPath();
        ctx.lineWidth = sprite.d / 2;
        ctx.strokeStyle = sprite.color;
        ctx.moveTo(sprite.x + sprite.tilt + sprite.r, sprite.y);
        ctx.lineTo(sprite.x + sprite.tilt, sprite.y + sprite.tilt + sprite.r);
        ctx.stroke();
        updateConfettiParticle(id);
      });
    }
    function render() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawConfetti();
      requestAnimationFrame(() => {
        render();
      });
    }
    setCanvasSize();
    bang();
    render();
  };
})(svs, _);

 })(window);